@import "../../../scss/mixins/_md.scss";
@import "../../../scss/mixins/_other.scss";
@import "../../../scss/functions/_size.scss";
@import "../../../scss/functions/_fontFamily.scss";


.show-pc {
  @include mq-sp {
    display: none;
  }
}
.show-sp {
  @include mq-pc {
    display: none;
  }
}

@mixin pcHiddenBr() {
  @include mq-pc {
    br {
      display: none;
    }
  }
}
@mixin spHiddenBr() {
  @include mq-sp {
    br {
      display: none;
    }
  }
}

.wrapper {
  position: fixed;
  top: 0;
  right: 0;
  padding: 5px;
}

.medal {
  display: inline-block;
  background: yellow;
  text-align: center;
  width:20px;
  height: 20px;
  line-height: 20px;
  border-radius: 50%;
  font-size: 12px;
  + .medal {
    margin-left: 3px;
  }
}

.inactive {
  background: #ccc;
}
