@import "../../../scss/mixins/_md.scss";
@import "../../../scss/mixins/_other.scss";
@import "../../../scss/functions/_size.scss";
@import "../../../scss/functions/_fontFamily.scss";


.show-pc {
  @include mq-sp {
    display: none;
  }
}
.show-sp {
  @include mq-pc {
    display: none;
  }
}

@mixin pcHiddenBr() {
  @include mq-pc {
    br {
      display: none;
    }
  }
}
@mixin spHiddenBr() {
  @include mq-sp {
    br {
      display: none;
    }
  }
}

.nav {
  position: fixed;
  top: 0;
  left: 0;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  @include fontMaruExtraBold;
  @include all_min(gap, 20, 8);
  @include all_min(padding, 25, 12);
}

.button {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  color: #26497D;
  text-align: center;
  border-radius: 50%;
  background: #FFF;
  @include all_min(width, 78, 55);
  @include all_min(height, 78, 55);
  @include all_min(font-size, 12, 7);
  @include hoverOpacity();
}

.isMenu {
  svg {
    @include mq-sp {
      @include sp_vw_min(width, 22);
    }
  }
}
.isMedal {
  svg {
    @include mq-sp {
      //@include sp_vw_min(margin-top, 3);
      @include sp_vw_min(width, 25);
    }
  }
}

.icon {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  @include all_min(width, 44, 30);
  @include all_min(height, 44, 30);
}

.text {
  font-size: 10px;
  color: #26497D;
  line-height: 1;
  @include all_min(margin-bottom, 5 ,5);
}
