@import "mixins/_md.scss";
@import "mixins/_other.scss";
@import "functions/_size.scss";
@import "functions/_fontFamily.scss";


.show-pc {
  @include mq-sp {
    display: none;
  }
}
.show-sp {
  @include mq-pc {
    display: none;
  }
}

@mixin pcHiddenBr() {
  @include mq-pc {
    br {
      display: none;
    }
  }
}
@mixin spHiddenBr() {
  @include mq-sp {
    br {
      display: none;
    }
  }
}

//@import url('https://fonts.googleapis.com/css2?family=M+PLUS+Rounded+1c:wght@700;800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=M+PLUS+Rounded+1c:wght@700;800&family=Noto+Sans+JP:wght@400&display=swap');
@import "~slick-carousel/slick/slick.css";


// react-slickで親コンテナーがflexだとバグるため、これを入れてる
* {
  min-width: 0;
  min-height: 0;
}

html,
body {
  user-select: none;
  padding: 0;
  margin: 0;
  width: 100%;
  height: 100%;
  color: var(--main-font-color);
  font-family: var(--font-base);
}


html {
  scroll-behavior: smooth;
}

img {
  max-width: 100%;
}

body {
  overflow: hidden;
}

#root {
  position: fixed;
  top: 0;
  left: 0;
  overflow: hidden auto;
  width: 100%;
  height: 100%;
  background: url('../img/common/bg.jpg') repeat;
}

#modal {
  //position: fixed;
  //width: 100%;
  //height: 100%;
}

*:focus {
  outline: none;
}
