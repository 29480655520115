@import "../../../scss/mixins/_md.scss";
@import "../../../scss/mixins/_other.scss";
@import "../../../scss/functions/_size.scss";
@import "../../../scss/functions/_fontFamily.scss";


.show-pc {
  @include mq-sp {
    display: none;
  }
}
.show-sp {
  @include mq-pc {
    display: none;
  }
}

@mixin pcHiddenBr() {
  @include mq-pc {
    br {
      display: none;
    }
  }
}
@mixin spHiddenBr() {
  @include mq-sp {
    br {
      display: none;
    }
  }
}

.wrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100%;
  background: #f4f4f4;
}

.bg {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.centering {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  @include yoko {
    margin-top: -50px;
  }
}

.main {
  @include yoko {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    align-items: center;
    justify-content: center;
    margin: 0 auto;
    width: 100%;
    max-width: 1440px;
  }
  @include tate {
    @include py_min(40, 40);
    @include px_min(40, 30);
  }
}

.title {
  //word-break: keep-all;
  @include fontMaruExtraBold;
  @include all_min(font-size, 58, 36);
  @include all_min(line-height, 80, 48);
  @include yoko {
    @include all_min(padding-left, 100, 30, true);
  }
  @include tate {
    //@include all_min(padding-top, 80, 85);
    //@include all_min(padding-left, 44, 44);
  }
}
.whiteTitle {
  color: #FFF;
}

.visual {
  position: relative;
  text-align: center;
  pointer-events: none;
  img {
    @include mq-pc {
      width: 100%;
      max-width: 772px;
    }
    @include mq-sp {
      width: 90%;
    }
    @include tate {
      //@include all_min(margin-top, -20, -20);
    }
  }
}

.buttonWrap {
  position: fixed;
  left: 0;
  width: 100vw;
  text-align: center;
  @include all_min(bottom, 50, 30);
}

.pageTransition {
  overflow: hidden;
  height: 100%;
  transform: translateZ(0);
  box-shadow: 0 0 84px 18px rgba(0,0,0,0.2);
}
