@import "../../../scss/mixins/_md.scss";
@import "../../../scss/mixins/_other.scss";
@import "../../../scss/functions/_size.scss";
@import "../../../scss/functions/_fontFamily.scss";


.show-pc {
  @include mq-sp {
    display: none;
  }
}
.show-sp {
  @include mq-pc {
    display: none;
  }
}

@mixin pcHiddenBr() {
  @include mq-pc {
    br {
      display: none;
    }
  }
}
@mixin spHiddenBr() {
  @include mq-sp {
    br {
      display: none;
    }
  }
}

//@import "variables";

.button {
  @include fontMaruExtraBold;
  position: relative;
  color: #CCC;
  display: inline-block;
  padding: 0 20px;
  text-align: center;
  background: #FFFFFF;
  border: 2px solid;
  box-shadow: 0 2px 0;
  border-radius: 100px;
  //+ .button {
  //  margin-left: 10px;
  //}
  @include all_min(min-width, 290, 290);
  @include all_min(font-size, 18, 18);
  @include all_min(line-height, 44, 50);
  @include px_min(20, 20);
  @include hover {
    transition: opacity 0.1s, border-shadow 0.1s;
    will-change: opacity, border-shadow;
    &:hover {
      opacity: 0.7;
    }
  }
  &:active {
    transform: translateY(2px);
    box-shadow: 0 0 0;
  }
}

.middle {
  @include all_min(min-width, 260, 260);
}

.colorBlue {
  color: #26497D;
  svg {
    stroke: #26497D;
  }
}

.colorBrown {
  color: #895D07;
  svg {
    stroke: #895D07;
  }
}

.externalIcon {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background-image: url('data:image/svg+xml;charset=utf8,%3Csvg%20width%3D%2217%22%20height%3D%2214%22%20viewBox%3D%220%200%2017%2014%22%20fill%3D%22none%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Cg%20clip-path%3D%22url(%23clip0_884_1373)%22%3E%3Cpath%20d%3D%22M12.9403%2011.0177H1.01493C0.74575%2011.0177%200.487615%2010.9133%200.297279%2010.7275C0.106944%2010.5417%200%2010.2897%200%2010.0269V0.9908C0%200.728023%200.106944%200.476024%200.297279%200.290213C0.487615%200.104402%200.74575%200%201.01493%200H12.9403C13.2095%200%2013.4676%200.104402%2013.658%200.290213C13.8483%200.476024%2013.9552%200.728023%2013.9552%200.9908V10.0269C13.9552%2010.2897%2013.8483%2010.5417%2013.658%2010.7275C13.4676%2010.9133%2013.2095%2011.0177%2012.9403%2011.0177ZM2.02985%209H12V1.9816H2.02985V9Z%22%20fill%3D%22%2326497D%22%2F%3E%3Cpath%20d%3D%22M15.9852%2013.9903H4.05985C3.79067%2013.9903%203.53254%2013.8859%203.3422%2013.7001C3.15187%2013.5143%203.04492%2013.2623%203.04492%2012.9995C3.04492%2012.7368%203.15187%2012.4848%203.3422%2012.299C3.53254%2012.1131%203.79067%2012.0087%204.05985%2012.0087H15V4C15%203.73722%2015.0772%203.44868%2015.2676%203.26287C15.4579%203.07706%2015.716%202.97266%2015.9852%202.97266C16.2544%202.97266%2016.5126%203.07706%2016.7029%203.26287C16.8932%203.44868%2017.0001%203.70068%2017.0001%203.96346V12.9995C17.0001%2013.2623%2016.8932%2013.5143%2016.7029%2013.7001C16.5126%2013.8859%2016.2544%2013.9903%2015.9852%2013.9903Z%22%20fill%3D%22%2326497D%22%2F%3E%3C%2Fg%3E%3Cdefs%3E%3CclipPath%20id%3D%22clip0_884_1373%22%3E%3Crect%20width%3D%2217%22%20height%3D%2214%22%20fill%3D%22white%22%2F%3E%3C%2FclipPath%3E%3C%2Fdefs%3E%3C%2Fsvg%3E');
  background-size: cover;
  @include all_min(right, 16, 25);
  @include all_min(width, 17, 17);
  @include all_min(height, 14, 14);
}
