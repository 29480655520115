@mixin tate() {
  @media all and (orientation: portrait) {
    @content
  }
}

@mixin yoko() {
  @media all and (orientation: landscape) {
    @content
  }
}

$breakpoint: 750;

@mixin mq-pc() {
  @media screen and (min-width:  #{$breakpoint + 1px}) {
    @content
  }
}

@mixin mq-sp() {
  @media screen and (max-width:  #{$breakpoint * 1px}) {
    @content
  }
}

@mixin mq-min-height($height) {
  @media screen and (min-height:  #{$height}px) {
    @content
  }
}

@mixin mq-max-height($height) {
  @media screen and (max-height:  #{$height}px) {
    @content
  }
}

@mixin hover() {
  @media (hover: hover) {
    @content
  }
}
