@import "../../../scss/mixins/_md.scss";
@import "../../../scss/mixins/_other.scss";
@import "../../../scss/functions/_size.scss";
@import "../../../scss/functions/_fontFamily.scss";


.show-pc {
  @include mq-sp {
    display: none;
  }
}
.show-sp {
  @include mq-pc {
    display: none;
  }
}

@mixin pcHiddenBr() {
  @include mq-pc {
    br {
      display: none;
    }
  }
}
@mixin spHiddenBr() {
  @include mq-sp {
    br {
      display: none;
    }
  }
}

//@import "variables";

.wrapper {
  z-index: var(--zIndex--modal);
  opacity: 0;
  pointer-events: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.7);
}

.content {
  z-index: 1; // safariで放射線の回転アニメーションがある場合にborder-radiusが効かないをバグを制御するため
  position: relative;
  overflow: hidden;
  background: url('../../../img/common/bg.jpg') repeat;

  @include mq-pc {
    border-radius: 20px;
    @include pc_vw_min(width, 1270);
    @include pc_vh_min(height, 748);
    box-shadow: 4px 4px 2px rgba(0, 0, 0, 0.1);
  }

  @include mq-sp() {
    border-radius: 10px;
    width: 94%;
    height: spPercent(561);
    box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.1);
  }
}

.closeButton {
  position: absolute;
  top: 0;
  left: 0;
  @include mq-pc {
    padding: 42px 10px 10px 48px;
  }
  @include mq-sp {
    padding: spMinVw(25) spMinVw(10) spMinVw(10) spMinVw(25);
  }
}
