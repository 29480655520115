@import "../../../scss/mixins/_md.scss";
@import "../../../scss/mixins/_other.scss";
@import "../../../scss/functions/_size.scss";
@import "../../../scss/functions/_fontFamily.scss";


.show-pc {
  @include mq-sp {
    display: none;
  }
}
.show-sp {
  @include mq-pc {
    display: none;
  }
}

@mixin pcHiddenBr() {
  @include mq-pc {
    br {
      display: none;
    }
  }
}
@mixin spHiddenBr() {
  @include mq-sp {
    br {
      display: none;
    }
  }
}

.wrapper {
  text-align: center;
  @include all_min(padding-top, 70, 70);
  @include all_min(padding-bottom, 40, 40);
}

.inner {
  position: relative;
}

.title {
  @include fontMaruExtraBold;
  @include all_min(font-size, 40, 24);
  @include all_min(line-height, 59, 36);
  color: #26497D;
}

.explain {
  @include all_min(margin-top, 16, 14);
  @include all_min(font-size, 20, 16);
  @include all_min(line-height, 36, 28);
  color: #3E3E3E;
}

.medalList {
  @include all_min(margin-top, 28, 25);
  ul {
    display: grid;
    justify-content: center;
    @include mq-pc {
      grid-template-columns: repeat(5, pcMinVw(79));
    }
    @include mq-sp {
      grid-template-columns: repeat(5, spMinVw(58));
    }
  }
}

.buttonWrap {
  @include all_min(margin-top, 36, 30);
  li + li {
    @include all_min(margin-top, 20, 20);
  }
}
