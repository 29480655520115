@import "../../../scss/mixins/_md.scss";
@import "../../../scss/mixins/_other.scss";
@import "../../../scss/functions/_size.scss";
@import "../../../scss/functions/_fontFamily.scss";


.show-pc {
  @include mq-sp {
    display: none;
  }
}
.show-sp {
  @include mq-pc {
    display: none;
  }
}

@mixin pcHiddenBr() {
  @include mq-pc {
    br {
      display: none;
    }
  }
}
@mixin spHiddenBr() {
  @include mq-sp {
    br {
      display: none;
    }
  }
}

.card {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  font-family: var(--font-maru);
  color: #A0A0A0;
  text-align: center;
  background: #FFF;
  border: 1px solid;
  border-radius: 8px;
  @include all_min(padding-top, 25, 15);
  @include all_min(width, 232, 161);
  @include all_min(height, 294, 203);
}

.cardActive {
  color: #26497D;
  box-shadow: 2px 2px 0 #26497D;
  @include hover {
    .medal {
      transition: transform .3s ease-out;
    }
    &:hover {
      .medal {
        transition: transform .3s ease-out;
        transform: scale(1.08);
      }
    }
  }
}

.inner {

}

.id {
  font-weight: bold;
  @include all_min(font-size, 28, 20);
}

.medal {
  margin: 0 auto;
  @include all_min(margin-top, 5, 5);
  @include all_min(width, 176, 121);
  @include all_min(height, 167, 111);

  img {
    width: 100%;
    height: 100%;

  }
}

.shadow {
  filter: drop-shadow(0 0 6px rgba(0,0,0,0.2));
}

.title {
  @include fontMaruExtraBold;
  @include all_min(margin-top, 5, 5);
  @include all_min(font-size, 18, 14);
  @include all_min(line-height, 22, 18);
}
