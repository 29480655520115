@import "../../../scss/mixins/_md.scss";
@import "../../../scss/mixins/_other.scss";
@import "../../../scss/functions/_size.scss";
@import "../../../scss/functions/_fontFamily.scss";


.show-pc {
  @include mq-sp {
    display: none;
  }
}
.show-sp {
  @include mq-pc {
    display: none;
  }
}

@mixin pcHiddenBr() {
  @include mq-pc {
    br {
      display: none;
    }
  }
}
@mixin spHiddenBr() {
  @include mq-sp {
    br {
      display: none;
    }
  }
}


.wrapper {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 100%;
  text-align: center;
  @include px_min(30, 30);
  @include all_min(padding-top, 133, 72);
  @include all_min(padding-bottom, 60, 40);
}

.flexTop {
  flex-grow: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}

.flexBottom {
  @include all_min(padding-top, 60, 40);
}

.title {
  font-family: var(--font-maru);
  letter-spacing: 0.05em;
  @include all_min(font-size, 34, 20);
  @include all_min(line-height, 46, 30);
}

.text {
  @include all_min(margin-top, 30, 16);
  @include all_min(font-size, 20, 16);
  @include all_min(line-height, 36, 30);
}

.note {
  @include all_min(margin-top, 16, 16);
  @include all_min(font-size, 16, 12);
  @include all_min(line-height, 30, 22);
  @include spHiddenBr;
}

.screenshot { // pc only
  @include pc_vw_min(margin-top, 50);
  @include pc_vw_min(gap, 40);
  display: flex;
  justify-content: center;
  img {
    @include pc_vw_min(width, 361, false);
  }
  p {
    margin-top: 10px;
    font-size: 14px;
  }
}

// Slider
.sliderWrap {
  //display: flex;
  //justify-content: center;
  //align-items: center;
  //min-height: 0;
  //min-width: 0;
}
.slide {
  text-align: center;

  .title {
    display: flex;
    justify-content: center;
    align-items: center;
    letter-spacing: 0.05em;
    @include px_min(26,26);
    @include mq-sp {
      @include sp_vw_min(min-height, 62);
    }
  }

  .textWrap {
    display: flex;
    justify-content: center;
    @include px_min(26,26);
    @include mq-sp {
      @include sp_vw_min(min-height, 90);
    }
  }
  .text {

  }

  .image {
    @include all_min(margin-top, 16, 16);
    img {
      margin: 0 auto;
      @include sp_vw_min(width, 303);
      @include sp_vw_min(height, 230);
      object-fit: contain;
    }
  }

  .note {
    @include mq-sp {
      @include sp_vw_min(margin-top, 10);
      @include sp_vw_min(font-size, 12);
      line-height: 1;
    }
  }
}
