@import "../../../scss/mixins/_md.scss";
@import "../../../scss/mixins/_other.scss";
@import "../../../scss/functions/_size.scss";
@import "../../../scss/functions/_fontFamily.scss";


.show-pc {
  @include mq-sp {
    display: none;
  }
}
.show-sp {
  @include mq-pc {
    display: none;
  }
}

@mixin pcHiddenBr() {
  @include mq-pc {
    br {
      display: none;
    }
  }
}
@mixin spHiddenBr() {
  @include mq-sp {
    br {
      display: none;
    }
  }
}

.wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  text-align: center;
}

.inner {

}

.title {
  @include fontMaruExtraBold;
  @include all_min(font-size, 32, 24);
}

.buttons {
  @include all_min(margin-top, 60, 50);
  @include mq-pc {
    display: flex;
    @include pc_vw_min(gap, 40);
  }

  button {
    @include mq-sp {
      @include sp_vw_min(margin-top, 20);
    }
  }
}
