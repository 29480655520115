@import "../../../scss/mixins/_md.scss";
@import "../../../scss/mixins/_other.scss";
@import "../../../scss/functions/_size.scss";
@import "../../../scss/functions/_fontFamily.scss";


.show-pc {
  @include mq-sp {
    display: none;
  }
}
.show-sp {
  @include mq-pc {
    display: none;
  }
}

@mixin pcHiddenBr() {
  @include mq-pc {
    br {
      display: none;
    }
  }
}
@mixin spHiddenBr() {
  @include mq-sp {
    br {
      display: none;
    }
  }
}

.wrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: var(--zIndex--modal);
  visibility: hidden;
  opacity: 0;
  pointer-events: none;
}

.closeButton {
  position: fixed;
  top: 0;
  left: 0;
  @include mq-pc {
    padding: 43px 10px 10px 49px;
  }
  @include mq-sp {
    padding: spMinVw(20) spMinVw(10) spMinVw(10) spMinVw(30);
  }
}
