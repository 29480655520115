@import "../../../scss/mixins/_md.scss";
@import "../../../scss/mixins/_other.scss";
@import "../../../scss/functions/_size.scss";
@import "../../../scss/functions/_fontFamily.scss";


.show-pc {
  @include mq-sp {
    display: none;
  }
}
.show-sp {
  @include mq-pc {
    display: none;
  }
}

@mixin pcHiddenBr() {
  @include mq-pc {
    br {
      display: none;
    }
  }
}
@mixin spHiddenBr() {
  @include mq-sp {
    br {
      display: none;
    }
  }
}


.wrapper {
  text-align: center;
}

.catch {
  color: #26497D;
  font-family: var(--font-maru);
  @include all_min(margin-top, 40, 26);
  @include all_min(font-size, 28, 22);
  @include all_min(line-height, 42, 32);
}

.children {
  @include all_min(margin-top, 20, 12);
  @include all_min(font-size, 20, 18);
  @include all_min(line-height, 36, 28);
}
