@import "../../../scss/mixins/_md.scss";
@import "../../../scss/mixins/_other.scss";
@import "../../../scss/functions/_size.scss";
@import "../../../scss/functions/_fontFamily.scss";


.show-pc {
  @include mq-sp {
    display: none;
  }
}
.show-sp {
  @include mq-pc {
    display: none;
  }
}

@mixin pcHiddenBr() {
  @include mq-pc {
    br {
      display: none;
    }
  }
}
@mixin spHiddenBr() {
  @include mq-sp {
    br {
      display: none;
    }
  }
}


.wrapper {
  font-family: var(--font-maru);
}

.menuWrap {
  position: absolute;
  transition: all .12s ease-out;
  background: #FFF;
  @include mq-pc {
    overflow: hidden;
    top: 10px;
    left: 10px;
    width: 50px;
    height: 50px;
    border-radius: 100px;
  }
  @include mq-sp {
    width: 94%;
    border-radius: 5px;
    transform: scale(0);
    transform-origin: 3% top;
    @include sp_vw_min(top, 58);
    @include sp_vw_min(left, 10);
  }
}

.menu {
  @include mq-pc {
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    //justify-content: center;
    align-items: center;
    width: 418px;
    height: 100%;
    text-align: center;
  }
  @include mq-sp {
    @include sp_vw_min(padding, 25);
  }
}

.button {
  @include mq-pc {
    flex-shrink: 0;
    &:first-child {
      margin-left: 77px;
      margin-right: 42px;
    }
  }
  @include mq-sp {
    + .button {
      @include sp_vw_min(margin-top, 20);
    }
  }
  @include hoverOpacity();
}

.toggleButton {
  overflow: hidden;
  position: absolute;
  top: 0;
  left: 0;
  flex-shrink: 0;
  background: #FFF;
  border-radius: 100px;
  @include all_min(margin, 10, 10);
  @include all_min(width, 50, 40);
  @include all_min(height, 50, 40);
  box-sizing: content-box;
}

.iconWrap {
  height: 100%;
  transition: transform .2s ease-out;

  @include hover {
    transition: opacity .1s;
    &:hover {
      opacity: 0.7;
    }
  }
}

.icon {
  display: flex;
  justify-content: center;
  align-items: center;
  @include all_min(width, 50, 40);
  @include all_min(height, 50, 40);
}

.iconHome {
  svg {
    @include mq-sp {
      @include sp_vw_min(width, 23);
      height: auto;
    }
  }
}
.iconClose {
  svg {
    @include mq-sp {
      @include sp_vw_min(width, 19);
      height: auto;
    }
  }
}


.isActive {
  .iconWrap {
    transform: translateY(-100%);
  }
  .menuWrap {
    @include mq-pc {
      width: 418px;
    }
    @include mq-sp {
      transform: scale(1);
    }
  }
}
