@import "../../../scss/mixins/_md.scss";
@import "../../../scss/mixins/_other.scss";
@import "../../../scss/functions/_size.scss";
@import "../../../scss/functions/_fontFamily.scss";


.show-pc {
  @include mq-sp {
    display: none;
  }
}
.show-sp {
  @include mq-pc {
    display: none;
  }
}

@mixin pcHiddenBr() {
  @include mq-pc {
    br {
      display: none;
    }
  }
}
@mixin spHiddenBr() {
  @include mq-sp {
    br {
      display: none;
    }
  }
}

.wrapper {
  //font-family: var(--font-maru);
  text-align: center;
  height: 100%;
}

.centering {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100%;
}

.titleWrap {
  position: relative;
  @include all_min(height, 297 , 149);
  @include mq-pc {
    @include pc_vw_min(width, 511, false);
  }
  span {
    opacity: 0;
  }
  svg {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) !important;
    //transform: scale(2.5) !important;
    @include mq-pc {
      width: 320% !important;
      height: 320% !important;
    }
    @include mq-sp {
      width: 320% !important;
      height: 320% !important;
    }
  }
}

.copy {
  font-size: 50px;
}

.title {
  margin-top: 60px;
  display: block;
  font-size: 120px;
}

.buttonWrap {
  width: 100%;
  @include all_min(margin-top, 40, 49);
}

.sound {
  font-weight: 500;
  @include all_min(margin-top, 22, 20);
  @include all_min(font-size, 14, 12);
  @include all_min(line-height, 26, 22);
  text-align: center;
  color: #3E3E3E;
}

.characters {
  > div {
    position: fixed;
  }
}

.momotaro {
  top: 50%;
  //@include all_min(left, -170, -90);
  //@include all_min(margin-top, -300, -330);
  @include mq-pc {
    left: -170px;
    margin-top: -300px;
  }
  @include mq-sp {
    left: -100px;
    margin-top: -380px;
  }
  img {
    @include all_min(width, 366, 208);
  }
}
.pigs {
  left: 50%;
  //@include all_min(bottom, -100, -50);
  //@include all_min(margin-left, -400, -90);
  @include mq-pc {
    bottom: -100px;
    margin-left: -400px;
  }
  @include mq-sp {
    bottom: -50px;
    margin-left: -100px;
  }
  img {
    @include all_min(width, 311, 177);
  }
}
.antgrass {
  top: 50%;
  @include mq-pc {
    right: -130px;
    margin-top: -100px;
  }
  @include mq-sp {
    right: -50px;
    margin-top: -300px;
  }
  img {
    @include all_min(width, 319, 181);
  }
}

.allText {
  z-index: -1;
  pointer-events: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  overflow: hidden;
  color: #FFF;
  div {
    &:first-of-type {
      @include fontMaru();
    }
    &:nth-of-type(2) {
      @include fontMaruBold();
    }
    &:nth-of-type(3) {
      @include fontMaruExtraBold();
    }
  }
  p {
    display: inline;
  }
}
