@import "../../../scss/mixins/_md.scss";
@import "../../../scss/mixins/_other.scss";
@import "../../../scss/functions/_size.scss";
@import "../../../scss/functions/_fontFamily.scss";


.show-pc {
  @include mq-sp {
    display: none;
  }
}
.show-sp {
  @include mq-pc {
    display: none;
  }
}

@mixin pcHiddenBr() {
  @include mq-pc {
    br {
      display: none;
    }
  }
}
@mixin spHiddenBr() {
  @include mq-sp {
    br {
      display: none;
    }
  }
}

.title {
  @include fontMaruExtraBold;
  text-align: center;
  * {
    vertical-align: middle;
  }
  p {
    letter-spacing: 0.05em;
    color: #26497D;
    @include all_min(font-size, 40, 24);
  }
  svg {
    @include all_min(margin-top, 15, 15);
    @include mq-sp {
      @include sp_vw_min(width, 200);
      height: auto;
    }
  }
}
