@import "../../../scss/mixins/_md.scss";
@import "../../../scss/mixins/_other.scss";
@import "../../../scss/functions/_size.scss";
@import "../../../scss/functions/_fontFamily.scss";


.show-pc {
  @include mq-sp {
    display: none;
  }
}
.show-sp {
  @include mq-pc {
    display: none;
  }
}

@mixin pcHiddenBr() {
  @include mq-pc {
    br {
      display: none;
    }
  }
}
@mixin spHiddenBr() {
  @include mq-sp {
    br {
      display: none;
    }
  }
}

.wrapper {
  //overflow-y: auto;
  //width: 100%;
  //height: 100%;
}

.inner {
  @include all_min(padding-top, 100, 34);
}

.content {
  @include all_min(padding-top, 30, 30);
  @include all_min(padding-bottom, 140, 140);
  position: relative;
  font-family: var(--font-maru);
  text-align: center;
}

.title {
  @include fontMaruExtraBold;
  @include all_min(font-size, 64, 44);
  opacity: 0;
  letter-spacing: 0.02em;
  color: #26497D;
}

.text {
  letter-spacing: 0.02em;
  @include all_min(margin-top, 30, 20);
  @include all_min(font-size, 24, 18);
  @include all_min(line-height, 42, 30);
}

:local(.image) {
  overflow: hidden;
  @include all_min(margin-top, 30, 10);
  > div {
    margin: 0 auto;
    position: relative;
    @include all_min(width, 533, 375);
    @include all_min(height, 400, 282);
  }
  :global {
    .mainImage, .subImage, .mainLottie, .subLottie {
      width: 100%;
      height: 100%;
      padding: 0 !important;
    }
    .mainLottie, .subLottie {
      svg {
        margin: 0;
      }
    }
  }
}

.buttons {
  @include all_min(margin-top, 60, 40);
  @include mq-pc {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    > * {
      + * {
        @include pc_vw_min(margin-left, 40)
      }
    }
  }
  @include mq-sp {
    flex-direction: column;
    > * {
      + * {
        @include sp_vw_min(margin-top, 20)
      }
    }
  }

}
