@import "../../../scss/mixins/_md.scss";
@import "../../../scss/mixins/_other.scss";
@import "../../../scss/functions/_size.scss";
@import "../../../scss/functions/_fontFamily.scss";


.show-pc {
  @include mq-sp {
    display: none;
  }
}
.show-sp {
  @include mq-pc {
    display: none;
  }
}

@mixin pcHiddenBr() {
  @include mq-pc {
    br {
      display: none;
    }
  }
}
@mixin spHiddenBr() {
  @include mq-sp {
    br {
      display: none;
    }
  }
}

.sliderWrap {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100%;
}
.slider {
  @include all_min(padding-top, 40, 40);
  @include all_min(padding-bottom, 40, 40);
  min-height: 0;
  min-width: 0;

  :global .slick-dots {
    @include sp_vw_min(padding-top, 15);
    display: flex !important;
    justify-content: center;

    li {
      overflow: hidden;
      width: 10px;
      height: 10px;
      border: 2px solid #26497D;
      border-radius: 50%;

      &.slick-active {
        background: #26497D;
      }

      + li {
        @include sp_vw_min(margin-left, 12);
      }
    }
  }
}

.buttonWrap {
  text-align: center;
  @include all_min(margin-top, 40, 40);
}
