@import "../../../scss/mixins/_md.scss";
@import "../../../scss/mixins/_other.scss";
@import "../../../scss/functions/_size.scss";
@import "../../../scss/functions/_fontFamily.scss";


.show-pc {
  @include mq-sp {
    display: none;
  }
}
.show-sp {
  @include mq-pc {
    display: none;
  }
}

@mixin pcHiddenBr() {
  @include mq-pc {
    br {
      display: none;
    }
  }
}
@mixin spHiddenBr() {
  @include mq-sp {
    br {
      display: none;
    }
  }
}

.wrapper {
  position: fixed;
  font-family: var(--font-maru);
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden auto;
}

.content {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100%;
  text-align: center;
  @include mq-pc {
    padding: 120px 0 100px;
  }
  @include mq-sp {
    @include sp_vw_min(padding-top, 140);
    @include sp_vw_min(padding-bottom, 100);
  }
}

.text {
  position: relative;
  svg {
    @include all_min(width, 538, 301);
    height: auto;
  }
  p {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    text-align: center;
    color: #3E3E3E;
    font-weight: 800;
    @include all_min(font-size, 32, 18);
    @include all_min(line-height, 68, 46);
  }
}

.selection {
  @include all_min(margin-top, 40, 20, true);

  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

// iOS14.1以前でflexでgapが使えないため、こんな感じに...
.list {
  @include mq-pc {
    @include pc_vw_min(margin-left, 20);
    @include pc_vw_min(margin-right, 20);
  }
  @include mq-sp {
    &:first-of-type {
      margin-right: spMinVw(11);
    }
    &:last-of-type {
      margin-right: spMinVw(11);
      margin-left: spMinVw(11);
    }
  }
}

.link {
  display: inline-block;
  @include all_min(width, 372, 167, true);
  @include all_min(height, 372, 167, true);
  @include all_min(font-size, 36, 20, true);
  @include all_min(line-height, 53, 30, true);
}

.image {
  @include hover {
    overflow: hidden;
    transition: transform .2s ease-out;
    &:hover {
      transform: scale(1.06);
    }
  }
}

.illust {
  // safariのhoverでちらつくバグのためにいれている
  z-index: 1;
  overflow: hidden;
  position: relative;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  border: 4px solid #FFF;
  background: #FFF;
}

.medalState {
  // safariのhoverでちらつくバグのためにいれている
  z-index: 2;
  position: relative;
  @include all_min(margin-top, -25, -9);
  @include py_min(10, 5, true);
  @include px_min(18, 12, true);
  display: inline-block;
  vertical-align: top;
  line-height: 0;
  background: #FFF;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.1);
  border-radius: 100px;
}
.medal {
  display: inline-block;
  + .medal {
    @include all_min(margin-left, 6, 3, true);
    margin-left: 6px;
  }
  svg {
    vertical-align: middle;
    @include all_min(width, 24, 14, true);
    height: auto;
  }
}
.medalActive {
  path {
    fill: #CBA523;
  }
}
.medalInactive {
  //opacity: 0.2;
  //filter: grayscale(100%);
}
