@import "../../../scss/mixins/_md.scss";
@import "../../../scss/mixins/_other.scss";
@import "../../../scss/functions/_size.scss";
@import "../../../scss/functions/_fontFamily.scss";


.show-pc {
  @include mq-sp {
    display: none;
  }
}
.show-sp {
  @include mq-pc {
    display: none;
  }
}

@mixin pcHiddenBr() {
  @include mq-pc {
    br {
      display: none;
    }
  }
}
@mixin spHiddenBr() {
  @include mq-sp {
    br {
      display: none;
    }
  }
}

.wrapper {
  //width: 100%;
  @include all_min(padding-top, 100, 60);
  @include all_min(padding-bottom, 60, 40);
}

.list {
  @include my_min(66, 30);

  @include mq-sp() {
    @include sp_vw_min(margin-left, -10);
    @include sp_vw_min(margin-right, -10);
  }
}

.listInner {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  margin-left: auto;
  margin-right: auto;
  @include mq-pc {
    max-width: 1440px;
    //gap: 10px
    > * {
      margin: 10px;
    }
  }
  @include mq-sp {
    @include sp_vw_min(max-width, 400);
    > * {
      @include sp_vw_min(margin-top, 13);
      &:nth-child(odd) {
        @include sp_vw_min(margin-right, 13);
      }
    }
  }
}

.empty {
  @include all_min(width, 232, 161);
}

.buttonWrap {
  width: 100%;
  text-align: center;
  //@include all_min(margin-bottom, 30, 40);
}
