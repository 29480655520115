@import "../../../scss/mixins/_md.scss";
@import "../../../scss/mixins/_other.scss";
@import "../../../scss/functions/_size.scss";
@import "../../../scss/functions/_fontFamily.scss";


.show-pc {
  @include mq-sp {
    display: none;
  }
}
.show-sp {
  @include mq-pc {
    display: none;
  }
}

@mixin pcHiddenBr() {
  @include mq-pc {
    br {
      display: none;
    }
  }
}
@mixin spHiddenBr() {
  @include mq-sp {
    br {
      display: none;
    }
  }
}

.policy {
}
.wrapper {
  background: #FFF;
  min-height: 100%;
  @include mq-pc {
    padding: 100px 135px 60px 125px;
    //max-width: 1070px;
  }
  @include mq-sp {
    padding: spMinVw(100) spMinVw(30) spMinVw(40);
  }
}

.inner {
  @include mq-pc {
    margin: 0 auto;
    max-width: 820px;
  }

}

.header {
}

.title {
  @include fontMaruExtraBold;
  letter-spacing: 0.05em;
  color: #26497D;
  @include all_min(font-size, 36, 22);
  @include all_min(line-height, 52, 32);
}

.explain {
  @include all_min(margin-top, 20, 20);
  @include all_min(font-size, 16, 14);
  @include all_min(line-height, 30, 26);
}

.policyIndex {
  @include all_min(margin-top, 40, 40);
  h3 {
    font-weight: bold;
    @include all_min(font-size, 24, 18);
    @include all_min(line-height, 30, 30);
  }

  ul {
    @include all_min(margin-top, 20, 20);
  }

  li {
    @include all_min(line-height, 36, 32);
  }

  a {
    font-weight: bold;
    text-decoration-line: underline;
    color: #26497D;
    @include all_min(font-size, 16, 14);
  }
}

.policyMain {
  margin-top: 60px;
  padding-top: 60px;
  border-top: 1px solid #26497D;
  @include all_min(margin-top, 60, 40);
  @include all_min(padding-top, 60, 40);

  dl + dl {
    @include all_min(margin-top, 50, 30);
  }

  dt {
    font-weight: bold;
    @include all_min(font-size, 24, 18);
    @include all_min(line-height, 30, 30);
  }

  dd {
    @include all_min(margin-top, 20, 20);
    @include all_min(font-size, 16, 14);
    @include all_min(line-height, 30, 26);

    > * {
      + * {
        margin-top: 20px;
      }
    }
  }

  ol {
    list-style-type: decimal;
    padding-left: 1em;
  }
}

.childOl {
  li {
    display: flex;
    list-style-type: none;
    counter-increment: cnt;
  }

  li::before {
    content: "（" counter(cnt) "）";
  }
  p {
    margin-left: 5px;
  }
}

.date {
  text-align: right;
  @include all_min(font-size, 16, 14);
  @include all_min(margin-top, 100, 30);
}

.buttonWrap {
  @include all_min(margin-top, 100, 50);
  text-align: center;
}

.closeButton {
  position: fixed;
  top: 0;
  left: 0;
  @include mq-pc {
    padding: 42px 10px 10px 48px;
  }
  @include mq-sp {
    padding: spMinVw(25) spMinVw(10) spMinVw(10) spMinVw(25);
  }
}

