@import "../../../scss/mixins/_md.scss";
@import "../../../scss/mixins/_other.scss";
@import "../../../scss/functions/_size.scss";
@import "../../../scss/functions/_fontFamily.scss";


.show-pc {
  @include mq-sp {
    display: none;
  }
}
.show-sp {
  @include mq-pc {
    display: none;
  }
}

@mixin pcHiddenBr() {
  @include mq-pc {
    br {
      display: none;
    }
  }
}
@mixin spHiddenBr() {
  @include mq-sp {
    br {
      display: none;
    }
  }
}

//@import "variables";
$zIndexDebug: 1000;

.debug {
  z-index: $zIndexDebug;
  position: fixed;
  bottom: 0;
  left: 0;
  padding: 5px;
  &__inner {
    display: none;
    padding: 20px 0;
  }

  input:checked {
    + .debug__inner {
      display: block;
    }
  }

  ul {
    margin: 0;
    padding: 0;
    list-style: none;
  }
  li {
    margin-top: 10px;
  }
  span {
    display: inline-block;
    font-size: 12px;
    background: #ccc;
    padding: 2px 8px;
    &:first-of-type {
      color: #FFF;
      background: #000;
      border-top-left-radius: 10px;
      border-bottom-left-radius: 10px;
    }
    &:nth-of-type(2) {
      border-top-right-radius: 10px;
      border-bottom-right-radius: 10px;
      background: #FFF;
    }
  }
  button {
    margin-left: 5px;
  }
}

.pageTransition {
  overflow: hidden;
  min-height: 100%;
  transform: translateZ(0);
  box-shadow: 0 0 84px 18px rgba(0,0,0,0.2);
}

.wrapper {
  height: 100%;
}

.nextButton {
  position: fixed;
  left: 0;
  width: 100vw;
  text-align: center;
  @include all_min(bottom, 50, 30);
}
