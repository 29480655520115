@import "../../../scss/mixins/_md.scss";
@import "../../../scss/mixins/_other.scss";
@import "../../../scss/functions/_size.scss";
@import "../../../scss/functions/_fontFamily.scss";


.show-pc {
  @include mq-sp {
    display: none;
  }
}
.show-sp {
  @include mq-pc {
    display: none;
  }
}

@mixin pcHiddenBr() {
  @include mq-pc {
    br {
      display: none;
    }
  }
}
@mixin spHiddenBr() {
  @include mq-sp {
    br {
      display: none;
    }
  }
}


.wrapper {
  @include fontMaruExtraBold;
  display: flex;
  //flex-direction: column;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100%;
  @include tate() {
    flex-direction: column;
  }
}

.box {
  //flex-shrink: 0;
  position: relative;
  width: 100%;
  height: 100%;
}

.linkWrap {
  position: absolute;
  left: 0;
  width: 100%;
  text-align: center;
  @include all_min(bottom, 30, 23);
  @include px_min(20, 20);
}

.link {
  position: relative;
  display: inline-block;
  width: 100%;
  text-align: left;
  background: #FFFFFF;
  border: 2px solid #504B45;
  box-sizing: border-box;
  box-shadow: 0 2px 0 #504B45;
  border-radius: 180px;

  @include hover {
    transition: opacity 0.1s, border-shadow 0.1s;
    will-change: opacity, border-shadow;
    &:hover {
      opacity: 0.7;
    }
  }
  &:active {
    transform: translateY(2px);
    box-shadow: 0 0 0;
  }

  @include mq-pc {
    padding: 10px 20px;
    max-width: 380px;
  }
  @include mq-sp {
    //width: spMaxVw(334);
    padding: spMinVw(10) spMinVw(20);
    width: spMinVw(334);
  }

  .linkInner {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .id {
    @include all_min(font-size, 28, 28);
    @include all_min(margin-right, 15, 10);
  }

  .text {
    @include all_min(font-size, 18, 16);
    @include all_min(line-height, 22, 20);
  }

  .svg {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    @include all_min(width, 20, 20);
    height: auto;
    @include all_min(right, 15, 15);
  }
}

