@import "../../../scss/mixins/_md.scss";
@import "../../../scss/mixins/_other.scss";
@import "../../../scss/functions/_size.scss";
@import "../../../scss/functions/_fontFamily.scss";


.show-pc {
  @include mq-sp {
    display: none;
  }
}
.show-sp {
  @include mq-pc {
    display: none;
  }
}

@mixin pcHiddenBr() {
  @include mq-pc {
    br {
      display: none;
    }
  }
}
@mixin spHiddenBr() {
  @include mq-sp {
    br {
      display: none;
    }
  }
}

.wrapper {
  @include fontMaruExtraBold;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #FFF;
  overflow: hidden auto;
  button, a {
    color: #26497D;
  }

  .external {
    &:after {
      content: "";
      display: inline-block;
      vertical-align: middle;
      @include all_min(margin-left, 10, 10);
      @include all_min(width, 17, 17);
      @include all_min(height, 14, 14);
      background-size: cover;
      background-image: url('data:image/svg+xml;charset=utf8,%3Csvg%20width%3D%2217%22%20height%3D%2214%22%20viewBox%3D%220%200%2017%2014%22%20fill%3D%22none%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Cg%20clip-path%3D%22url(%23clip0_892_1378)%22%3E%3Cpath%20d%3D%22M12.9403%2011.0177H1.01493C0.74575%2011.0177%200.487615%2010.9133%200.297279%2010.7275C0.106944%2010.5417%200%2010.2897%200%2010.0269V0.9908C0%200.728023%200.106944%200.476024%200.297279%200.290213C0.487615%200.104402%200.74575%200%201.01493%200H12.9403C13.2095%200%2013.4676%200.104402%2013.658%200.290213C13.8483%200.476024%2013.9552%200.728023%2013.9552%200.9908V10.0269C13.9552%2010.2897%2013.8483%2010.5417%2013.658%2010.7275C13.4676%2010.9133%2013.2095%2011.0177%2012.9403%2011.0177ZM2.02985%209H12V1.9816H2.02985V9Z%22%20fill%3D%22%2326497D%22%2F%3E%3Cpath%20d%3D%22M15.9852%2013.9901H4.05985C3.79067%2013.9901%203.53254%2013.8857%203.3422%2013.6999C3.15187%2013.5141%203.04492%2013.2621%203.04492%2012.9993C3.04492%2012.7365%203.15187%2012.4845%203.3422%2012.2987C3.53254%2012.1129%203.79067%2012.0085%204.05985%2012.0085H15V3.99976C15%203.73698%2015.0772%203.44844%2015.2676%203.26263C15.4579%203.07681%2015.716%202.97241%2015.9852%202.97241C16.2544%202.97241%2016.5126%203.07681%2016.7029%203.26263C16.8932%203.44844%2017.0001%203.70044%2017.0001%203.96321V12.9993C17.0001%2013.2621%2016.8932%2013.5141%2016.7029%2013.6999C16.5126%2013.8857%2016.2544%2013.9901%2015.9852%2013.9901Z%22%20fill%3D%22%2326497D%22%2F%3E%3C%2Fg%3E%3Cdefs%3E%3CclipPath%20id%3D%22clip0_892_1378%22%3E%3Crect%20width%3D%2217%22%20height%3D%2214%22%20fill%3D%22white%22%2F%3E%3C%2FclipPath%3E%3C%2Fdefs%3E%3C%2Fsvg%3E');
    }
  }
}

.content {
  display: flex;
  align-items: center;
  padding: 100px 0;
  min-height: 100%;
  @include all_min(padding-left, 160, 30, true);
}

.mainMenu {
  @include all_min(font-size, 38, 24);
  li {
    + li {
      @include all_min(margin-top, 30, 20);
    }
  }

  a, button {
    @include hoverOpacity();
  }

  .external {
    &:after {
      @include all_min(margin-left, 15, 15);
    }

  }
}

.homeIcon {
  @include all_min(margin-top, 50, 42);
  @include all_min(width, 360, 275);
  @include all_min(padding-top, 16, 13);
  @include all_min(padding-bottom, 12, 13);
  text-align: center;
  border: 2px solid #26497D;
  box-sizing: border-box;
  border-radius: 100px;
  box-shadow: 0 2px 0;
  .top {
    @include all_min(font-size, 19, 14);
    @include all_min(line-height, 14, 14);
  }
  .bottom {
    @include all_min(margin-top, 10, 10);
    @include all_min(font-size, 28, 20);
  }
  @include hoverOpacity();
  &:active {
    transform: translateY(2px);
    box-shadow: 0 0 0;
  }
}

.shareMenu {
  @include all_min(margin-top, 80, 50);
  p {
    @include all_min(font-size, 26, 16);
    @include fontMaruBold;
  }
  ul {
    @include all_min(margin-top, 30, 20);
  }
  li {
    display: inline-block;
    @include all_min(margin-right, 44, 30);
  }
  a {
    display: block;
    @include hoverOpacity();
  }
}

.isTwt {
  svg {
    @include mq-sp {
      @include sp_vw_min(width, 34);
      height: auto;
    }
  }
}

.isFb {
  svg {
    @include mq-sp {
      @include sp_vw_min(width, 33);
      height: auto;
    }
  }
}

.companyMenu {
  @include all_min(margin-top, 50, 30);
  @include all_min(font-size, 26, 14);
  li {
    display: inline-block;
    @include all_min(margin-right, 40, 40);
    &:last-of-type {
      margin-right: 0;
    }
  }
  a {
    display: inline-block;
    @include hoverOpacity();
  }
}

//.externalIcon {
//  vertical-align: middle;
//  @include all_min(width, 17, 17);
//  @include all_min(margin-left, 10, 6);
//}

.closeButton {
  position: absolute;
  top: 0;
  left: 0;
  @include mq-pc {
    padding: 42px 10px 10px 48px;
  }
  @include mq-sp {
    padding: spMinVw(20) spMinVw(10) spMinVw(10) spMinVw(30);
  }
}
