@import "../../../scss/mixins/_md.scss";
@import "../../../scss/mixins/_other.scss";
@import "../../../scss/functions/_size.scss";
@import "../../../scss/functions/_fontFamily.scss";


.show-pc {
  @include mq-sp {
    display: none;
  }
}
.show-sp {
  @include mq-pc {
    display: none;
  }
}

@mixin pcHiddenBr() {
  @include mq-pc {
    br {
      display: none;
    }
  }
}
@mixin spHiddenBr() {
  @include mq-sp {
    br {
      display: none;
    }
  }
}

.wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100%;
  text-align: center;
  @include py_min(100, 100);
}

.catch {
  color: #26497D;
  @include fontMaruExtraBold;
  @include all_min(font-size, 20, 20);
  @include all_min(line-height, 36, 30);
}

.explain {
  @include all_min(margin-top, 20, 15);
}

.buttonWrap {
  @include all_min(margin-top, 70, 40);
}
