@import "../../../scss/mixins/_md.scss";
@import "../../../scss/mixins/_other.scss";
@import "../../../scss/functions/_size.scss";
@import "../../../scss/functions/_fontFamily.scss";


.show-pc {
  @include mq-sp {
    display: none;
  }
}
.show-sp {
  @include mq-pc {
    display: none;
  }
}

@mixin pcHiddenBr() {
  @include mq-pc {
    br {
      display: none;
    }
  }
}
@mixin spHiddenBr() {
  @include mq-sp {
    br {
      display: none;
    }
  }
}

.wrapper {
  @include all_min(margin-top, 40 + 25, 30 + 21); // デザイン上の余白 + ゲットしたメダルでネガティブマージン分
}
.inner {
  opacity: 0;
  @include all_min(width, 532, 335);
  @include all_min(padding-bottom, 40, 30);
  margin: 0 auto;
  background: #FFF;
  border-radius: 10px;
}
.title {
  text-align: center;
  p {
    @include all_min(margin-top, -25, -21);
    @include all_min(width, 362, 300);
    @include all_min(font-size, 20, 16);
    @include all_min(line-height, 50, 42);
    display: inline-block;
    color: #FFF;
    background: #26497D;
    border-radius: 30px;
  }
}

.medalZero {
  opacity: 0;
  @include all_min(padding-top, 70, 70);
  @include all_min(font-size, 22, 22);
  @include all_min(line-height, 30, 30);
  font-weight: 600;
  letter-spacing: 0.02em;
  color: #3E3E3E;
  @include pcHiddenBr;
}

.list {
  @include all_min(padding-top, 50, 40);
  @include all_min(padding-left, 90, 25);
  @include all_min(padding-right, 90, 25);
}

.badge {
  img {
    @include all_min(width, 96, 82);
  }
  &::after {
    content: "";
    display: block;
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    @include all_min(width, 8, 8);
    @include all_min(height, 14, 14);
    background-image: url('data:image/svg+xml;charset=utf8,%3Csvg%20width%3D%228%22%20height%3D%2214%22%20viewBox%3D%220%200%208%2014%22%20fill%3D%22none%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Cpath%20d%3D%22M1%2013L7%207L1%201%22%20stroke%3D%22%2326497D%22%20stroke-width%3D%222%22%20stroke-linecap%3D%22round%22%20stroke-linejoin%3D%22round%22%2F%3E%3C%2Fsvg%3E');
    background-size: cover;
  }

  + .badge {
    @include all_min(margin-top, 30, 20);
  }
}

.badgeTitle {
  @include all_min(margin-left, 30, 21);
  @include all_min(padding-right, 20, 20);
  @include all_min(font-size, 26, 22);
  @include all_min(line-height, 36, 30);
  color: #26497D;
  letter-spacing: 0.02em;
  text-align: left;
}

.link {
  display: flex;
  align-items: center;
  @include hoverOpacity();
}

.goToMedals {
  opacity: 0;
  @include all_min(margin-top, 58, 50);
  @include all_min(font-size, 20, 20);
  @include all_min(line-height, 30, 30);
  letter-spacing: 0.02em;
  color: #26497D;
}
