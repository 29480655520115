@import "mixins/_md.scss";
@import "mixins/_other.scss";
@import "functions/_size.scss";
@import "functions/_fontFamily.scss";


.show-pc {
  @include mq-sp {
    display: none;
  }
}
.show-sp {
  @include mq-pc {
    display: none;
  }
}

@mixin pcHiddenBr() {
  @include mq-pc {
    br {
      display: none;
    }
  }
}
@mixin spHiddenBr() {
  @include mq-sp {
    br {
      display: none;
    }
  }
}

:root {
  // Font
  --font-base: 'Noto Sans JP',-apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen,
  Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
  --font-maru: 'M PLUS Rounded 1c';

  // Color
  --main-font-color: #3E3E3E;
  --bg-yellow-color: #FFFAF0;

  // Z-Index
  --zIndex--modal:     1000;
  --zIndex--overlay:    500;
  --zIndex--nav:        100;

  // Story
  --story-header-height-base: 48;
  --story-header-height-px: calc(var(--story-header-height-base) * 1px);
}

