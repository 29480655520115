@import "../../../scss/mixins/_md.scss";
@import "../../../scss/mixins/_other.scss";
@import "../../../scss/functions/_size.scss";
@import "../../../scss/functions/_fontFamily.scss";


.show-pc {
  @include mq-sp {
    display: none;
  }
}
.show-sp {
  @include mq-pc {
    display: none;
  }
}

@mixin pcHiddenBr() {
  @include mq-pc {
    br {
      display: none;
    }
  }
}
@mixin spHiddenBr() {
  @include mq-sp {
    br {
      display: none;
    }
  }
}

.block {
  + .block {
    margin-top: 20px;
  }
}
.title {
  margin-bottom: 10px;
  padding-bottom: 5px;
  font-size: 24px;
  font-weight: bold;
  border-bottom: 2px solid #000;
}
.subTitle {
  margin-bottom: 10px;
  font-weight: bold;
}
.table {
  font-size: 14px;
  border-collapse:  collapse;
  th,td {
    padding: 2px 4px;
    border: solid 1px;
  }
  input {
    padding: 4px 10px;
    width: 100px;
    background: #FFF;
  }
}

.explain {
  font-size: 12px;
}

.scroll {
  width: 100%;
  overflow-x: auto;
}
