@import "../../../scss/mixins/_md.scss";
@import "../../../scss/mixins/_other.scss";
@import "../../../scss/functions/_size.scss";
@import "../../../scss/functions/_fontFamily.scss";


.show-pc {
  @include mq-sp {
    display: none;
  }
}
.show-sp {
  @include mq-pc {
    display: none;
  }
}

@mixin pcHiddenBr() {
  @include mq-pc {
    br {
      display: none;
    }
  }
}
@mixin spHiddenBr() {
  @include mq-sp {
    br {
      display: none;
    }
  }
}

.main {
  pointer-events: none;
  position: relative;
  opacity: 0;
}

.enquete {
  @include all_min(top, 25, 16);
  @include all_min(right, 25, 13);
  @include all_min(width, 260, 169);
  @include all_min(line-height, 50, 48);
  @include all_min(font-size, 20, 14);
  @include fontMaru();
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  color: #FFFFFF;
  background: #26497D;
  border-radius: 50px;
  svg {
    @include all_min(width, 24, 20);
    @include all_min(height, 24, 20);
    @include all_min(margin-right, 10, 8);
  }
  p {
    @include all_min(margin-right, 7, 2);
  }

  @include hoverOpacity();
}

.mute {
  position: fixed;
  text-align: center;
  @include all_min(top, 122, 90);
  @include all_min(left, 25, 13);
  @include all_min(width, 72, 50);
  @include all_min(height, 43, 32);

  .icon {
    display: flex;
    justify-content: center;
    align-items: center;
    @include all_min(height, 30, 20);
  }

  svg {
    height: auto;
  }
  .on {
    @include all_min(width, 42.93, 30);
  }
  .off {
    @include all_min(width, 42.93, 30);
  }

  .text {
    @include mq-sp {
      svg {
        @include sp_vw_min(width, 42);
      }
    }
  }

  @include hoverOpacity();
}
