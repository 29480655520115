@import "../../../../scss/mixins/_md.scss";
@import "../../../../scss/mixins/_other.scss";
@import "../../../../scss/functions/_size.scss";
@import "../../../../scss/functions/_fontFamily.scss";


.show-pc {
  @include mq-sp {
    display: none;
  }
}
.show-sp {
  @include mq-pc {
    display: none;
  }
}

@mixin pcHiddenBr() {
  @include mq-pc {
    br {
      display: none;
    }
  }
}
@mixin spHiddenBr() {
  @include mq-sp {
    br {
      display: none;
    }
  }
}

.lottie {
  position: relative;
  width: 100%;
  height: 100%;

  > div {
    position: absolute;
    height: 100%;
    width: 100%;
  }
}
