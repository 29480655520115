@use "sass:math";

$pcDesignWidth: 1440;
$pcDesignHeight: 900;

$spDesignWidth: 375;
$spDesignHeight: 640;

// PC WIDTH
@function pcWw($size) {
  @return math.div($size, $pcDesignWidth) * 100 * 1vw
}
@function pcMaxVw($size) {
  @return max(#{$size * 1px}, #{pcWw($size)})
}
@function pcMinVw($size) {
  @return min(#{$size * 1px}, #{pcWw($size)})
}
@mixin pc_vw_max($prop, $number, $isShrink: true) {
  @if $isShrink {
    #{$prop} : pcMaxVw($number)
  } @else {
    #{$prop}: $number * 1px
  }
}
@mixin pc_vw_min($prop, $number, $isShrink: true) {
  @if $isShrink {
    #{$prop} : pcMinVw($number)
  } @else {
    #{$prop}: $number * 1px
  }
}

// PC HEIGHT
@function pcVh($size) {
  @return math.div($size, $pcDesignHeight) * 100 * 1vh
}
@function pcMaxVh($size) {
  @return max(#{$size * 1px}, #{pcVh($size)})
}
@function pcMinVh($size) {
  @return min(#{$size * 1px}, #{pcVh($size)})
}
@mixin pc_vh_max($prop, $number, $isShrink: true) {
  @if $isShrink {
    #{$prop} : pcMaxVh($number)
  } @else {
    #{$prop}: $number * 1px
  }
}
@mixin pc_vh_min($prop, $number, $isShrink: true) {
  @if $isShrink {
    #{$prop} : pcMinVh($number)
  } @else {
    #{$prop}: $number * 1px
  }
}

// SP WIDTH
@function spVw($size) {
  @return math.div($size, $spDesignWidth) * 100 * 1vw
}
@function spMaxVw($size) {
  @return max(#{$size * 1px}, #{spVw($size)})
}
@function spMinVw($size) {
  @return min(#{$size * 1px}, #{spVw($size)})
}
@mixin sp_vw_max($prop, $number) {
  #{$prop} : spMaxVw($number)
}
@mixin sp_vw_min($prop, $number) {
  #{$prop} : spMinVw($number)
}

// SP HEIGHT
@function spVh($size) {
  @return math.div($size, $spDesignHeight) * 100 * 1vh
}
@function spPercent($size) {
  @return math.div($size, $spDesignHeight) * 100 * 1%
}
@function spMaxVh($size) {
  @return max(#{$size * 1px}, #{spPercent($size)})
}
@function spMaxPercent($size) {
  @return max(#{$size * 1px}, #{spVh($size)})
}
@function spMinVh($size) {
  @return min(#{$size * 1px}, #{spVh($size)})
}
@mixin sp_vh_max($prop, $number) {
  #{$prop} : spMaxVh($number)
}
@mixin sp_percent_max($prop, $number) {
  #{$prop} : spPercent($number)
}
@mixin sp_vh_min($prop, $number) {
  #{$prop} : spMinVh($number)
}


//-------------------
// $prop     プロパティを指定
// $pc       PCのサイズ
// $sp       SPのサイズ
// $isShrink PCで横幅の縮小に応じて、サイズも縮小するか
//-------------------
@mixin all_min_max($prop, $pc, $sp, $isShrink: false) {
  @include mq-pc {
    @include pc_vw_min($prop, $pc, $isShrink);
  }
  @include mq-sp {
    @include sp_vw_max($prop, $sp);
  }
}


//-------------------
// $prop     プロパティを指定
// $pc       PCのサイズ
// $sp       SPのサイズ
// $isShrink PCで横幅の縮小に応じて、サイズも縮小するか
//-------------------
@mixin all_min($prop, $pc, $sp, $isShrink: false) {
  @include mq-pc {
    @include pc_vw_min($prop, $pc, $isShrink);
  }
  @include mq-sp {
    @include sp_vw_min($prop, $sp);
  }
}

//-------------------
// $prop     プロパティを指定
// $pc       PCのサイズ
// $sp       SPのサイズ
// $isShrink PCで横幅の縮小に応じて、サイズも縮小するか
//-------------------
@mixin px_min($pc, $sp, $isShrink: false) {
  @include mq-pc {
    @include pc_vw_min(padding-left, $pc, $isShrink);
    @include pc_vw_min(padding-right, $pc, $isShrink);
  }
  @include mq-sp {
    @include sp_vw_min(padding-left, $sp);
    @include sp_vw_min(padding-right, $sp);
  }
}
@mixin py_min($pc, $sp, $isShrink: false) {
  @include mq-pc {
    @include pc_vw_min(padding-top, $pc, $isShrink);
    @include pc_vw_min(padding-bottom, $pc, $isShrink);
  }
  @include mq-sp {
    @include sp_vw_min(padding-top, $sp);
    @include sp_vw_min(padding-bottom, $sp);
  }
}
@mixin mx_min($pc, $sp, $isShrink: false) {
  @include mq-pc {
    @include pc_vw_min(margin-left, $pc, $isShrink);
    @include pc_vw_min(margin-right, $pc, $isShrink);
  }
  @include mq-sp {
    @include sp_vw_min(margin-left, $sp);
    @include sp_vw_min(margin-right, $sp);
  }
}
@mixin my_min($pc, $sp, $isShrink: false) {
  @include mq-pc {
    @include pc_vw_min(margin-top, $pc, $isShrink);
    @include pc_vw_min(margin-bottom, $pc, $isShrink);
  }
  @include mq-sp {
    @include sp_vw_min(margin-top, $sp);
    @include sp_vw_min(margin-bottom, $sp);
  }
}

