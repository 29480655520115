@import "../../../scss/mixins/_md.scss";
@import "../../../scss/mixins/_other.scss";
@import "../../../scss/functions/_size.scss";
@import "../../../scss/functions/_fontFamily.scss";


.show-pc {
  @include mq-sp {
    display: none;
  }
}
.show-sp {
  @include mq-pc {
    display: none;
  }
}

@mixin pcHiddenBr() {
  @include mq-pc {
    br {
      display: none;
    }
  }
}
@mixin spHiddenBr() {
  @include mq-sp {
    br {
      display: none;
    }
  }
}

.showNumber {
  display: flex;
  gap: 20px;
  justify-content: center;
  margin: 10px 0;

  input {
    background: #FFF;
    border: 1px solid #000;
  }
}

.list {
  h2 {
    font-weight: bold;
    margin-bottom: 5px;
    text-decoration: underline;
    a {
      color: blue;

      &:hover {
        opacity: 0.6;
      }
    }
  }
  li + li {
    margin-top: 20px;
    padding-top: 20px;
    border-top: 1px solid #000;
  }
}

.pictureWrap {
  position: relative;
  width: 300px;
  height: 200px;
  * {
    margin: 0!important;
    padding: 0!important;
  }
}

.navi {
  display: flex;
  gap: 20px;
  justify-content: center;
  margin: 10px 0;
  button {
    color: blue;
    text-decoration: underline;
  }
}
