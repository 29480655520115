@import "../../../scss/mixins/_md.scss";
@import "../../../scss/mixins/_other.scss";
@import "../../../scss/functions/_size.scss";
@import "../../../scss/functions/_fontFamily.scss";


.show-pc {
  @include mq-sp {
    display: none;
  }
}
.show-sp {
  @include mq-pc {
    display: none;
  }
}

@mixin pcHiddenBr() {
  @include mq-pc {
    br {
      display: none;
    }
  }
}
@mixin spHiddenBr() {
  @include mq-sp {
    br {
      display: none;
    }
  }
}

//@import "variables";

.wrapper {
  position: relative;
  width: 100%;
  height: 100%;
}

.bg {
  position: relative;
  width: 100%;
  height: 100%;

  img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.imageWrap {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  img, svg {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
}

// state
@include yoko() {
  .imageWrap {
    padding-top: 48px;
    padding-bottom: 80px;
  }
}

// 余白関係
.positionUnder {
  @include yoko {
    img, svg {
      margin-top: 80px;
    }
  }
}

// 余白関係
.positionUpper {
  @include yoko {
    img, svg {
      margin-top: -48px;
    }
  }
}

