@mixin fontMaru($weight: 700) {
  font-family: var(--font-maru);
  font-weight: $weight;
}
@mixin fontMaruBold() {
  @include fontMaru()
}
@mixin fontMaruExtraBold() {
  @include fontMaru(800)
}
