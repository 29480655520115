@import "../../../scss/mixins/_md.scss";
@import "../../../scss/mixins/_other.scss";
@import "../../../scss/functions/_size.scss";
@import "../../../scss/functions/_fontFamily.scss";


.show-pc {
  @include mq-sp {
    display: none;
  }
}
.show-sp {
  @include mq-pc {
    display: none;
  }
}

@mixin pcHiddenBr() {
  @include mq-pc {
    br {
      display: none;
    }
  }
}
@mixin spHiddenBr() {
  @include mq-sp {
    br {
      display: none;
    }
  }
}

// Common
.subTitle {
  @include fontMaruExtraBold();
  @include all_min(font-size, 28, 20);
  @include all_min(line-height, 42, 32);
  color: #26497D;
}
.explain {
  @include all_min(margin-top, 15, 10);
  @include all_min(font-size, 18, 18);
  @include all_min(line-height, 32, 28);
}
.image {
  margin: 0 auto;
  @include all_min(margin-top, 25, 30);
  @include all_min(width, 510, 315);
  @include all_min(height, 280, 172);
  border: 5px solid #FFF;
  box-sizing: content-box;
}

// HowTo
.wrapper {
  @include all_min(padding-top, 100, 100);
  @include all_min(padding-bottom, 40, 40);
  text-align: center;
}

.box {
  + .box {
    @include all_min(margin-top, 60, 50);
  }
}

.firstSubTitle {
  @include all_min(margin-top, 40, 28);
}

.closeButton {
  margin-top: 100px;
  text-align: center;
}

// Init HowTo
$scaleMaxHeight900: 0.8;
$scaleMaxHeight700: 0.7;
$scaleMaxHeightSp700: 0.9;
.initWrap {
  //@include all_min(padding-top, 50, 60);
  //@include all_min(padding-bottom, 20, 20);
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  @include px_min(30, 30);

  // 縦幅が狭い場合に「次へ」が見えず、ユーザーが次のアクションがわからないとのことなので、
  // 縦幅に応じて、コンテンツを縮小

  // -- PC --
  @include mq-pc {
    @include mq-max-height(900) {

      :global {
        .wave-title {
          @include pc_vw_min(font-size, 40 * $scaleMaxHeight900, false);
        }
        .wave-svg {
          @include pc_vw_min(margin-top, 15 * $scaleMaxHeight900, false);
          @include pc_vw_min(width, 253 * $scaleMaxHeight900, false);
        }
      }
      .subTitle {
        @include pc_vw_min(font-size, 28 * $scaleMaxHeight900, false);
        @include pc_vw_min(line-height, 42 * $scaleMaxHeight900, false);
      }
      .explain {
        @include pc_vw_min(margin-top, 15 * $scaleMaxHeight900, false);
        @include pc_vw_min(font-size, 18 * $scaleMaxHeight900, false);
        @include pc_vw_min(line-height, 36 * $scaleMaxHeight900, false);
      }
      .image {
        @include pc_vw_min(margin-top, 25 * $scaleMaxHeight900, false);
        @include pc_vw_min(width, 510 * $scaleMaxHeight900, false);
        height: auto;
      }
    }
    @include mq-max-height(700) {

      :global {
        .wave-title {
          @include pc_vw_min(font-size, 40 * $scaleMaxHeight700, false);
        }
        .wave-svg {
          @include pc_vw_min(margin-top, 15 * $scaleMaxHeight700, false);
          @include pc_vw_min(width, 253 * $scaleMaxHeight700, false);
        }
      }
      .subTitle {
        @include pc_vw_min(font-size, 28 * $scaleMaxHeight700, false);
        @include pc_vw_min(line-height, 42 * $scaleMaxHeight700, false);
      }
      .explain {
        @include pc_vw_min(margin-top, 15 * $scaleMaxHeight700, false);
        @include pc_vw_min(font-size, 18 * $scaleMaxHeight700, false);
        @include pc_vw_min(line-height, 36 * $scaleMaxHeight700, false);
      }
      .image {
        @include pc_vw_min(margin-top, 25 * $scaleMaxHeight700, false);
        @include pc_vw_min(width, 510 * $scaleMaxHeight700, false);
        height: auto;
      }
    }

    // コンテンツを縮小し続けると、可読性がわるくなるので、「次へ」を固定に変更
    @include mq-max-height(650) {
      display: block;
      @include pc_vw_min(padding-top, 20, false);
      @include pc_vw_min(padding-bottom, 20, false);
      .image {
        @include pc_vw_min(margin-bottom, 100, false);
        height: auto;
      }
      .buttonWrap {
        position: absolute;
        bottom: 0;
        left: 0;
        text-align: center;
        width: 100%;
      }
    }
  }

  // -- SP --
  @include mq-sp {
    @include mq-max-height(700) {

      :global {
        .wave-title {
          @include sp_vw_min(font-size, 24 * $scaleMaxHeightSp700);
        }

        .wave-svg {
          @include sp_vw_min(margin-top, 15 * $scaleMaxHeightSp700);
          @include sp_vw_min(width, 200 * $scaleMaxHeightSp700);
        }
      }
      .subTitle {
        @include sp_vw_min(font-size, 20 * $scaleMaxHeightSp700);
        @include sp_vw_min(line-height, 32 * $scaleMaxHeightSp700);
      }
      .explain {
        @include sp_vw_min(margin-top, 10 * $scaleMaxHeightSp700);
        @include sp_vw_min(font-size, 18 * $scaleMaxHeightSp700);
        @include sp_vw_min(line-height, 28 * $scaleMaxHeightSp700);
      }
      .image {
        @include sp_vw_min(margin-top, 30 * $scaleMaxHeightSp700);
        @include sp_vw_min(width, 314 * $scaleMaxHeightSp700);
        height: auto;
      }
    }

    // コンテンツを縮小し続けると、可読性がわるくなるので、「次へ」を固定に変更
    @include mq-max-height(590) {
      display: block;
      @include sp_vw_min(margin-bottom, 100);
      @include sp_vw_min(padding-top, 40);
      @include sp_vw_min(padding-bottom, 20);
      .image {
        height: auto;
      }
      .buttonWrap {
        position: absolute;
        bottom: 0;
        left: 0;
        text-align: center;
        width: 100%;
      }
    }
  }
}

.buttonWrap {
  @include all_min(padding-top, 32, 40);
  @include all_min(padding-bottom, 20, 20);
}
