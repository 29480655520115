@import "../../../scss/mixins/_md.scss";
@import "../../../scss/mixins/_other.scss";
@import "../../../scss/functions/_size.scss";
@import "../../../scss/functions/_fontFamily.scss";


.show-pc {
  @include mq-sp {
    display: none;
  }
}
.show-sp {
  @include mq-pc {
    display: none;
  }
}

@mixin pcHiddenBr() {
  @include mq-pc {
    br {
      display: none;
    }
  }
}
@mixin spHiddenBr() {
  @include mq-sp {
    br {
      display: none;
    }
  }
}

.wrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100%;
  background: #EFEFEF;
}

.grid {
  display: grid;
  height: 100%;
  @include yoko {
    grid-template-columns: repeat(3, 1fr);
  }
  @include tate {
    grid-template-columns: 1fr;
    grid-template-rows: 1fr 1fr 1fr;
    height: calc(100% - 80px);
    @include mq-sp() {
      grid-template-rows: 1fr 1fr #{spMinVw(190)};
    }
  }
}

.textWrap {
  position: relative;
  height: 100%;
  overflow-y: auto;
  @include tate() {
    order: 2;
  }
}

.textInner {
  font-family: var(--font-maru);
  flex-shrink: 0;
  @include yoko {
    @include all_min(padding-top, 48, 48);
  }
  @include tate {
    @include sp_vw_min(padding-top, 15);
    @include sp_vw_min(padding-left, 15);
    @include sp_vw_min(padding-right, 15);
  }
}
.text {
  line-height: 1.8;
  letter-spacing: 0.06em;
  @include tate {
    @include mq-pc {
      font-size: 24px;
    }
  }
  @include yoko {
    @include mq-pc {
      margin: pcMinVw(94) pcMinVw(24) pcMinVw(94) pcMinVw(50);
      font-size: 24px;
    }
    @include mq-sp {
      margin: spMinVw(20) spMinVw(10) spMinVw(94);
    }
  }
  @include mq-sp {
    @include sp_vw_min(font-size, 16);
    @include sp_vw_min(line-height, 28);
  }
}
.storyPicture {
  flex-shrink: 0;
  grid-column: span 2 / span 2;
  width: 100%;
  height: 100%;

  @include tate() {
    order: 1;
    grid-row: span 2 / span 2;
  }
}
