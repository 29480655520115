@import "../../scss/mixins/_md.scss";
@import "../../scss/mixins/_other.scss";
@import "../../scss/functions/_size.scss";
@import "../../scss/functions/_fontFamily.scss";


.show-pc {
  @include mq-sp {
    display: none;
  }
}
.show-sp {
  @include mq-pc {
    display: none;
  }
}

@mixin pcHiddenBr() {
  @include mq-pc {
    br {
      display: none;
    }
  }
}
@mixin spHiddenBr() {
  @include mq-sp {
    br {
      display: none;
    }
  }
}


.wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  min-height: 100%;
  text-align: center;
}

.flexTop {
  flex-grow: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  //@include py_min(40, 60);
  //@include all_min(padding-bottom, 40, 60);
}
.flexBottom {
  //@include all_min(padding-bottom, 60, 40);
}
