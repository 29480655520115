@import "../../../scss/mixins/_md.scss";
@import "../../../scss/mixins/_other.scss";
@import "../../../scss/functions/_size.scss";
@import "../../../scss/functions/_fontFamily.scss";


.show-pc {
  @include mq-sp {
    display: none;
  }
}
.show-sp {
  @include mq-pc {
    display: none;
  }
}

@mixin pcHiddenBr() {
  @include mq-pc {
    br {
      display: none;
    }
  }
}
@mixin spHiddenBr() {
  @include mq-sp {
    br {
      display: none;
    }
  }
}


.wrapper {
  @include fontMaruExtraBold;
  color: #26497D;
  text-align: center;
}

.id {
  position: relative;
  display: inline-block;
  @include all_min(padding-bottom, 10, 10);
  @include all_min(font-size, 46, 36);
  &::after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    border-top: 2px solid;
  }
}

.title {
  @include all_min(margin-top, 10, 10);
  @include all_min(font-size, 38, 24);
  @include all_min(line-height, 56, 42);
  letter-spacing: 0.02em;
}
