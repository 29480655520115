@import "../../../scss/mixins/_md.scss";
@import "../../../scss/mixins/_other.scss";
@import "../../../scss/functions/_size.scss";
@import "../../../scss/functions/_fontFamily.scss";


.show-pc {
  @include mq-sp {
    display: none;
  }
}
.show-sp {
  @include mq-pc {
    display: none;
  }
}

@mixin pcHiddenBr() {
  @include mq-pc {
    br {
      display: none;
    }
  }
}
@mixin spHiddenBr() {
  @include mq-sp {
    br {
      display: none;
    }
  }
}


.wrapper {
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100%;
  font-family: var(--font-maru);
  text-align: center;
  //background-color: #FFFAED;
  //background-image: url("../../../img/story/bg_medal_get.svg");
  //background-size: cover;
  //background-position: center;
}

.title {
  img {
    height: auto;
    @include all_min(width, 390, 220);
  }
}

.medal {
  @include all_min(margin-top, 13, 10);
  img {
    height: auto;
    @include all_min(width, 408, 251);
    filter: drop-shadow(0 0 6px rgba(0,0,0,0.2));
    transform: translate3d(0.01px, 0.01px, 0.01px);
  }
}

.detail {
  @include all_min(margin-top, -11, -5);
}

$scale830: 0.8;
@include mq-pc {
  @include mq-max-height(830) {
    .title img {
      width: 390 * $scale830 * 1px;
    }
    .medal img {
      width: 408 * $scale830 * 1px;
    }
    .detailInner {
      transform-origin: center top;
      transform: scale($scale830);
    }
  }
}

$scale690: 0.6;
@include mq-pc {
  @include mq-max-height(690) {
    .title img {
      width: 390 * $scale690 * 1px;
    }
    .medal img {
      width: 408 * $scale690 * 1px;
    }
    .detailInner {
      transform-origin: center top;
      transform: scale($scale690);
    }
  }
}

$scale560: 0.5;
$scale560Sp: 0.8;
@include mq-max-height(560) {
  .title img {
    @include all_min(width, 390 * $scale560, 220 * $scale560Sp);
  }
  .medal img {
    @include all_min(width, 408 * $scale560, 251 * $scale560Sp);
  }
  .detailInner {
    transform-origin: center top;
    @include mq-pc {
      transform: scale($scale560);
    }
    @include mq-sp {
      transform: scale($scale560Sp);
    }
  }
}

$scale490: 0.4;
$scale490Sp: 0.6;
@include mq-max-height(490) {
  .title img {
    @include all_min(width, 390 * $scale490, 220 * $scale490Sp);
  }
  .medal img {
    @include all_min(width, 408 * $scale490, 251 * $scale490Sp);
  }
  .detailInner {
    transform-origin: center top;
    @include mq-pc {
      transform: scale($scale490);
    }
    @include mq-sp {
      transform: scale($scale490Sp);
    }
  }
}
