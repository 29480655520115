@import "../../../scss/mixins/_md.scss";
@import "../../../scss/mixins/_other.scss";
@import "../../../scss/functions/_size.scss";
@import "../../../scss/functions/_fontFamily.scss";


.show-pc {
  @include mq-sp {
    display: none;
  }
}
.show-sp {
  @include mq-pc {
    display: none;
  }
}

@mixin pcHiddenBr() {
  @include mq-pc {
    br {
      display: none;
    }
  }
}
@mixin spHiddenBr() {
  @include mq-sp {
    br {
      display: none;
    }
  }
}

.wrapper {
  position: relative;
  @include px_min(30, 30);
}

.header {
  @include all_min(padding-top, 82, 55, true);
}

.main {
  @include mq-pc {
    display: flex;
    align-items: center;
    margin: 20px auto 0;
    max-width: 1060px;
    @include pc_vw_min(gap, 45);
  }
  @include mq-sp {

  }
}

.left {
  flex-shrink: 0;
  text-align: center;
  img {
    @include all_min(width, 336, 198);
    filter: drop-shadow(0 0 6px rgba(0,0,0,0.2));
  }
}

.right {
  width: 100%;
  min-width: 0;
}

.headline {
  font-family: var(--font-maru);
  letter-spacing: 0.02em;
  @include all_min(font-size, 28, 22);
  @include all_min(line-height, 40, 34);
  @include mq-sp {
    @include sp_vw_min(margin-top, 20);
  }
}

.content {
  letter-spacing: 0.02em;
  @include all_min(margin-top, 20, 15);
  @include all_min(font-size, 20, 16);
  @include all_min(line-height, 34, 30);
}

.buttons {
  display: grid;
  text-align: center;
  @include all_min(gap, 20, 20);
  @include all_min(margin-top, 20, 100);
  @include all_min(padding-bottom, 40, 100);
}
