@import "../../../scss/mixins/_md.scss";
@import "../../../scss/mixins/_other.scss";
@import "../../../scss/functions/_size.scss";
@import "../../../scss/functions/_fontFamily.scss";


.show-pc {
  @include mq-sp {
    display: none;
  }
}
.show-sp {
  @include mq-pc {
    display: none;
  }
}

@mixin pcHiddenBr() {
  @include mq-pc {
    br {
      display: none;
    }
  }
}
@mixin spHiddenBr() {
  @include mq-sp {
    br {
      display: none;
    }
  }
}


.noise {
  pointer-events: none;
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0.4;
  mix-blend-mode: color-dodge;
  width: 100%;
  height: 100%;
  background: url("../../../img/story/noise.jpg");
  @include mq-sp {
    background-size: 90%;
  }
}
